.super-admin-body {
  padding: 0 60px 0 60px;
  height: 100%;
  margin: 0 60px 0 60px;
}

.topbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  gap: 10px;
}
