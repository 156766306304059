* {
    box-sizing: border-box;
}

.flag {
    width: calc(100vh * 1.8);
    height: calc(100vw / 1.8);
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    background-image: repeating-linear-gradient(#b41d31 0%, #921828 7.69%, #fff 7.7%, #fff 15.385%);
}

.canton {
    display: flex;
    flex-wrap: wrap;
    height: 53.85%;
    width: 40.1%;
    padding: 1.5% 2.2%;
    background-image: linear-gradient(135deg, #39386e, #1f1e3b);
}

.star {
    background-color: #fff;
    -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.star,
.space {
    flex-basis: calc(100% / 11);
}

@supports (display: grid) {
    .canton {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
    }
}
