.blurred-content{
  filter: blur(6px);
  -webkit-filter: blur(6px);
  opacity: 0.3;
  animation: fade-in-out 2s ease-in-out infinite;
}

@keyframes fade-in-out {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
