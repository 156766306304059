:root{
    --left-padding: 150px;
    --right-padding: 150px;
}
html{
    scroll-behavior: smooth;
}

.landing-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    /*background: radial-gradient(circle, rgba(0,26,255,1) 0%, rgba(255,255,255,1) 100%);*/
    background: rgb(194,200,252);
}

.landing-container .scrollable{
    height: 100vh;
    padding-left: var(--left-padding);
    padding-right: var(--right-padding);
}

.landing-container .main-content{
    display: grid;
    grid-template-rows: 75px 1fr;
    padding-top: 60px;
    padding-bottom: 60px;
}

.main-content .top-bar{
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    animation-name: slide-down, fade-in;
    opacity: 0;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.top-bar .logo-container{
    background-image: url('/public/images/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-blend-mode: difference;
}

.top-bar .navigation-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

}

.navigation-container .navigation-button{
    color: gray;
}

.navigation-button[aria-label="main"]{
    display: flex;
    height: 100%;
    width: 120px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    background-color: blue;
    color: white;
    border-radius: 12px;
}

.left-content.description{
    overflow: hidden;
}

.main-content .main-content-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;

}

.main-content-container .left-content{
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.left-content .caption{
    font-size: 72px;
    position: relative;
    animation-name: slide-right, scale-down, fade-in;
    opacity: 0;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.left-content .description{
    position: relative;
    animation-name: slide-right, scale-down, fade-in;
    opacity: 0;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.left-content .big-button-container{
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 25px;
    position: relative;
    animation-name: slide-up, scale-down, fade-in;
    opacity: 0;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.main-content-container .right-image-container{
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    background-image: url('/public/images/screenshots.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: difference;
    animation-name: slide-left, fade-in;
    opacity: 0;
    left: 5000px;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.big-button-container .big-button{
    border-radius: 12px;
    display: flex;
    padding: 20px 60px 20px 60px;
    justify-content: space-between;
    align-items: center;
    border: blue 1px solid;
    font-size: 36px;
}

.big-button[aria-label="normal"]{
    background-color: blue;
    color: white;
}

.big-button[aria-label="inverted"]{
    background-color: white;
    color: blue;
}

.services-content{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-gap: 50px;
    justify-content: center;
    align-items: center;
}

.services-content .service-container{
    width: 100%;
    height: 50%;
    display: grid;
    padding: 50px;
    grid-template-rows: 1fr 1fr 2fr;
    border: white 1px solid;
    border-radius: 45px;
}

.service-container .icon{
    background-color: #d2d5ff;
    padding: 20px;
    border-radius: 20px;
}

.client-reviews-content{
    width: 100%;
}

.button{
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@keyframes slide-down {
    0% {
        opacity: 0;
        top: -1000px;
    }
    1% {
        opacity: 1;
    }
    100% {
        top: 0;
    }
}

@keyframes slide-left {
    0% {
        opacity: 0;
        transform: translateX(5000px);
    }
    1% {
        opacity: 1;
    }
    100% {
        left: 0;
    }
}

@keyframes slide-right {
    0% {
        opacity: 0;
        left: -5000px;
    }
    1% {
        opacity: 1;
    }
    100% {
        left: 0;
    }
}

@keyframes scale-down {
    0% {
        transform: scale(2);
        opacity: 0;
    }
    1%{
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide-up {
    0% {
        top: 1000px;
        opacity: 0;
    }
    1%{
        opacity: 1;
    }
    100% {
        top: 0;
    }
}
