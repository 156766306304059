$font-stack: Titillium Web, sans-serif;
$primary-background: #2D77FC;
$primary-text: #003EB0;

// border
$input-border-radius: 14px;
$input-border-style: 2px solid rgba(0, 0, 0, 0.25);

// font
@mixin semibold-titillium {
  font-family: $font-stack;
  font-weight: 600;
  font-size: 1rem;
}