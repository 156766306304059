.dropzone-container{
    border: 1px dashed gray;
    text-align: center;
}

.thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
}

.thumb:hover .thumb-avatar{
    opacity: 1;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
}

.thumb-avatar{
    opacity: 0;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
}



.thumb:hover{
    opacity: .5;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;

}

.thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
    position: relative;
}

.img {
    display: block;
    width: auto;
    height: 100%;
}

.thumb-remove-btn{
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.thumb-remove-all-btn{
    margin-bottom: 10px;
}

