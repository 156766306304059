@import '../../styles/input-styles';

svg {
  height: 1.3rem;
}

.multi-label {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border: $input-border-style;

  min-width: 20rem;
  padding: 2px 9px;
  border-radius: $input-border-radius;
  max-height: 1.7rem;
}

.item-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  min-width: 85%;

  div, svg {
    margin: 0 10px 0 0;
  }
}

.item-label {
  @include semibold-titillium;

  display: inline-flex;
  align-items: center;
  //min-width: 20%;

  div, svg {
    margin: 0 2px;
  }
}

.operation-symbol {
  width: 11px;
}

.input-incremental {
  @include semibold-titillium;

  // display
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;

  // border
  border-radius: 0.7rem;
  border: 1px solid rgba(0, 0, 0, 0.25);

  // height & width constraints
  min-width: 5rem;
  max-height: 1.5rem;

  // color
  background-color: rgba(255, 255, 255, 0.7);
  color: black;

  :hover {
    cursor: pointer;
  }

  svg {
    margin: 0;
    fill: black !important;
  }

  div {
    margin: 0;
  }

  .item-count-input {
    @include semibold-titillium;

    border: none;
    text-align: center;
    white-space: pre;
    background-color: rgba(255, 255, 255, 0);
  }
}

.line-5 {
  //transform: rotate(-91deg);
  height: 12px;
  width: 0px;
  border: 0.0625rem solid #949494;
}

.line-6 {
  //transform: rotate(-91deg);
  height: 12px;
  width: 0px;
  border: 0.0625rem solid #949494;
}

.text-6 {
  @include semibold-titillium;

  text-align: left;
  vertical-align: top;
}

.remove-button {
  border-radius: 1rem;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);

  height: 1.2em;
  width: 1.2em;

  display: flex;
  align-items: center;
  padding: 3px;
  justify-content: center;

  :hover {
    cursor: pointer;
  }

  svg {
    fill: white;
    width: .7rem;
  }
}

@mixin theme($theme-name, $primary-background-color, $header-text-color, $primary-text-color) {
  .#{$theme-name} {
    display: inline-flex;

    .multi-label {
      background-color: $primary-background-color;
      color: $primary-text-color;
    }

    svg {
      fill: $primary-text-color;
    }
  }
}

@include theme("default", white, black, black);
@include theme("selected", $primary-background, $primary-text, white);