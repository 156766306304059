@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;500&display=swap');

#root {
    letter-spacing: 1px;
}
.rbc-toolbar button {
    font-family: inherit
}
.innerComponent h6 {
    position: relative;
    width:fit-content;
    padding-right: 5px;
    color: black;
}
