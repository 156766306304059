h1 {text-align: center; font-family: 'Ubuntu', sans-serif; color: #D0D3D4;}
.flag {
    margin: auto;
    height: 18px;
    width: 30px;
    overflow: hidden;
    border-radius: 5px;
}
.stripe {
    display: block;
    width: 100%;
    height: 33.3%;
}
.black {
    background-color: #000000;
}
.red {
    background-color: #FF0000;
}
.gold{
    background-color: #FFCC00;
}
