$primary: #102542;
$secondary: #EFEFEF;


.table-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 0;
    margin: 0;
    box-shadow: 0 5px 5px #6e6e6e;
    min-height: calc(100vh - 120px);
    height: calc(100vh - 120px);
}

.upper-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;

}

.table-title{
    margin-right: auto;
    margin-left: 20px;
    font-size: 28px;
}

.table-header{
    display: inline-flex;
    width: 100%;
    justify-content: right;
    background-color: $secondary;
    position: sticky;
    top: 0;
    z-index: 11;
    padding: 10px 20px 30px 0;
    gap: 10px;
}

.table-head th:first-child{
    padding-left: 20px;
}

.table-action-button-container{
    display: inline-flex;
    gap: 15px;
}

.table-head{
    background-color: $secondary;
    position: sticky;
    top: 0;
    padding-bottom: 10px;
    z-index: 11;
}

.filter-container{
    padding-top: 10px;
    padding-bottom: 20px;
}

.column-header-container{
}
.table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 1em;
    table-layout: fixed;
}

.table-row img{
    height: auto;
    width: auto;
    max-width: 175px;
    max-height: 175px;
}

.table-body:nth-child(odd){
    background-color: gray;
}

.table-row:nth-child(even){
    background-color: #f8f8f8;
}

.table-row td{
    padding-top:20px;
    padding-bottom:20px;
    padding-left:10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-row td:first-child{
    padding-left:20px;
    padding-right:0;
}

.table-row{
    border-bottom: 1px solid #e5e5e5;
}

.column-header {
    text-align: left;
}

.table-footer{
    display: inline-flex;
    gap: 10px;
    height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: white;
    position:sticky;
    bottom: 0;
    z-index:10;
    flex: none;
}

.cursor-pointer{
    cursor: pointer;
}

.select-none{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.no-record-msg{
    display: flex;
    height: 100%;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.page-index-info{
    font-size: 14px;
}

.navigate-input{
    max-width: 30px;
    text-align: center;
}

.page-navigate{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.filter-number-container{
    flex-direction: row;
    display: flex;
    gap: 5px;
}